.csv-data  { 
  display: flex;
  align-items: stretch;
}

.csv-data {
  .details {
    margin-left: 10px;
    margin-bottom: .5px;
  }
}

.vl {
  border-left: 1px solid #20586c;
  margin: 0px 5px;
}

.app-container {
  flex: 1;
  padding: 0 10px 0 10px;
}

@media screen {
  @media (min-width: 1024px) {
    .app-container {
      padding: 0 50px 0 50px;
    }
  }
}