.code-mirror-wrapper {
  border: 1px solid #034876;
  border-radius: 4px;
}

.CodeMirror {
  background-color: #030a14 !important;
  color: #8E98A5 !important;
  height: 300px;
  font-size: 16px;
  border-radius: 4px;
}

.expand > .CodeMirror {
  height: auto;
}

.CodeMirror-gutter,
.CodeMirror-linenumbers {
  background-color: #013d68;
}

.CodeMirror-linenumber {
  text-align: center;
}


.MuiTablePagination-toolbar, .MuiTablePagination-actions svg {
  color: #8E98A5;
}

.MuiTablePagination-selectIcon {
  color: #8E98A5!important;
}

.MuiTablePagination-actions button:disabled svg {
  color: #000;
}

.error {
  color: #ff5c6f;
  background-color: rgba(255,92,111,.2);
}

.duplicate {
  color: #ffc107;
  text-decoration: underline;
}

.msgs-container {
  margin: 1rem 0rem;
  border-radius: 0.5rem;
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
}

.error-container {
  display: flex;
  background-color: rgba(255,92,111,.2);
  padding: 1rem;
  align-items: center;
}

.warning-container {
  display: flex;
  background-color: rgba(255,204,0, 0.2);
  padding: 1rem;
}

.warning-text, .error-text {
  font-size: 1em;
}


@media screen and (max-width: 500px) {
  .CodeMirror {
    width: auto;
    font-size: 0.9rem;
  }
  /* .code-mirror-wrapper {
    max-width: 23rem;
  } */
  .warning-text, .error-text {
    font-size: 0.8em;
  }
}

.CodeMirror-activeline-background {
    background: #e8f2ff;
}

.CodeMirror-focused .CodeMirror-activeline-background {
    background: #e8f2ff;
}

.CodeMirror:not(.CodeMirror-focused) .CodeMirror-activeline-background {
    background: #013d68;
}


